import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { H1 } from 'src/components/shared/text'
import { PrimaryButton } from 'src/components/shared/buttons'
import { media } from 'src/utils/style-helper'

const Container = styled.section`
  background-color: ${(props) => props.theme.colors.pink};
  color: ${(props) => props.theme.colors.navy};
  width: 100%;
  position: relative;
  overflow: hidden;
  ${media.mobile`
    padding: 40px 14px 375px 14px;
  `}
  ${media.tablet`
    padding: 80px 40px;
  `}
`

const InternalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${media.mobile`
    align-items: center;
  `}
  ${media.tablet`
    max-width: 1400px;
    align-items: flex-start;
  `}
`

const StyledH1 = styled(H1)`
  white-space: pre-line;
  ${media.mobile`
    margin-bottom: 40px;
    font-size: 60px;
    text-align: center;
  `}
  ${media.tablet`
    margin-bottom: 80px;
    text-align: left;
    font-size: 88px;
  `}
  ${media.desktop`
    margin-bottom: 100px;
    font-size: 133px;
  `}
`

const Doggo = styled.div`
  position: absolute;
  bottom: 0;
  ${media.mobile`
    width: 88%;
    max-height: 350px;
    margin-top: 30px;
  `}
  ${media.tablet`
    width: 40%;
    max-width: 600px;
    max-height: none;
    right: 40px;
  `}
`

const ShopBanner = () => (
  <StaticQuery
    query={ShopBannerQuery}
    render={(data) => (
      <Container>
        <InternalWrapper>
          <StyledH1>{`Love fuzzballs\nof all shapes\nand sizes?`}</StyledH1>
          <OutboundLink href="https://www.petfinn.com/products">
            <PrimaryButton copy="Shop Finn" arrow />
          </OutboundLink>
        </InternalWrapper>
        <Doggo>
          <GatsbyImage
            image={getImage(data.louie)}
            alt="Brown dog"
            style={{ width: '100%', height: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </Doggo>
      </Container>
    )}
  />
)

const ShopBannerQuery = graphql`
  query {
    louie: file(relativePath: { regex: "/louie.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }
  }
`

export default ShopBanner
