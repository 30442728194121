import React from 'react'
import styled from 'styled-components'

import { media } from 'src/utils/style-helper'

const Wrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 0 14px;
  `}
    ${media.tablet`
    padding: 0 30px;
  `}
  ${media.desktop`
    max-width: 1265px;
    padding: 0 40px;
  `}
`

const Container = ({ children }) => <Wrapper>{children}</Wrapper>

export default Container
