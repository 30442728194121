import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { MonoP } from 'src/components/shared/text'

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.navy};
  margin-bottom: 20px;
  padding-bottom: 6px;
`

const Title = styled(MonoP)`
  color: ${(props) => props.theme.colors.navy};
`

const MonoHeader = ({ copy }) => {
  return (
    <Container>
      <Title>{copy}</Title>
    </Container>
  )
}

MonoHeader.propTypes = {
  copy: PropTypes.string.isRequired,
}

export default MonoHeader
