import React from 'react'
import styled from 'styled-components'
import StickyBox from 'react-sticky-box'
import { StaticQuery, graphql } from 'gatsby'

import LargeBlogCard from 'src/components/shared/LargeBlogCard'

const Stick = styled(StickyBox)`
  width: 100%;
  margin-top: -50px;
  padding-top: 50px;
`

const FeaturedPost = ({ data }) => {
  const postData = data.allMarkdownRemark.edges[0].node

  return (
    <Stick>
      <LargeBlogCard post={postData.frontmatter} />
    </Stick>
  )
}

const FeaturedPostQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { featuredpost: { eq: true }, draft: { eq: false } } }
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            slug
            draft
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
            date
            description
            tags
            title
            heroalt
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={FeaturedPostQuery}
    render={(data) => <FeaturedPost data={data} />}
  />
)
