import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'src/components/Layout'
import Homepage from 'src/components/Homepage'
import SEO from 'src/components/shared/SEO'

const HomepagePage = ({ data }) => {
  const blogPosts = data.allMarkdownRemark.edges.map((post) => post.node)
  return (
    <>
      <SEO
        title="Sidekick Dog Blog | Our Blog | Finn"
        description="Better nutrition is just the start. Get all the training tips, grooming advice, and dog health insights to help you navigate the journey of pet parenthood."
        keywords={['Dog Blog']}
      />
      <Layout>
        <Homepage posts={blogPosts} />
      </Layout>
    </>
  )
}

export default HomepagePage

export const HomepagePageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          featuredpost: { ne: true }
          draft: { eq: false }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          id
          frontmatter {
            draft
            slug
            title
            large
            templateKey
            tags
            heroalt
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 250, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`
