import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'

import Container from 'src/components/shared/Container'
import MonoHeader from 'src/components/shared/MonoHeader'
import { H6 } from 'src/components/shared/text'
import { ArrowButton } from 'src/components/shared/buttons'

import { media } from 'src/utils/style-helper'

const InternalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 80px 14px 50px 14px;
  `}
  ${media.tablet`
    padding: 80px 0 50px 0;
  `}
  ${media.desktop`
    max-width: 1265px;
    padding: 120px 30px 60px 10px;
  `}
`

const Quote = styled(H6)`
  &:before {
    content: '“';
    position: absolute;
    margin-left: -16px;
  }
  ${media.mobile`
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-right: 10px;
  `}
  ${media.tablet`
    font-size: 30px;
    padding-right: 20px;
    line-height: 36px;
  `}
  ${media.desktop`
    font-size: 36px;
    line-height: 46px;
  `}
`

const VetQuote = () => (
  <StaticQuery
    query={VetQuoteQuery}
    render={(data) => {
      const { quote, url } = data.allMarkdownRemark.edges[0].node.frontmatter

      return (
        <Container>
          <InternalWrapper>
            <MonoHeader copy="Words from the vet" />
            <Quote>{quote}”</Quote>
            <Link to={url} aria-label={quote}>
              <ArrowButton label={quote} />
            </Link>
          </InternalWrapper>
        </Container>
      )
    }}
  />
)

const VetQuoteQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { secondquote: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            quote
            url
          }
        }
      }
    }
  }
`

export default VetQuote
