import React from 'react'

import TopSection from 'src/components/Homepage/TopSection'
import ShopBanner from 'src/components/Homepage/ShopBanner'
import VetQuote from 'src/components/Homepage/VetQuote'
import MasonryPosts from 'src/components/shared/MasonryPosts'
import EmailCapture from 'src/components/shared/EmailCapture'

const Homepage = ({ posts }) => {
  const featuredPosts = posts.slice(0, 4)
  const remainingPosts = posts.slice(4)
  return (
    <>
      <TopSection featuredPosts={featuredPosts} />
      <ShopBanner />
      <VetQuote />
      <MasonryPosts posts={remainingPosts} />
      <EmailCapture />
    </>
  )
}

export default Homepage
