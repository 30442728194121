import React from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'

import FeaturedPost from 'src/components/Homepage/FeaturedPost'
import SmallBlogCard from 'src/components/shared/SmallBlogCard'
import MonoHeader from 'src/components/shared/MonoHeader'
import { LargeP } from 'src/components/shared/text'
import { ArrowButton } from 'src/components/shared/buttons'
import { media } from 'src/utils/style-helper'

import SittingDog from 'src/assets/img/logos/sitting-finn.svg'

const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 50px 30px;
  ${media.mobile`
    grid-template-columns: 1fr;
    padding: 0 14px 80px 14px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    padding: 25px 30px 80px 30px;
  `}
  ${media.desktop`
    max-width: 1265px;
    padding: 30px 40px 160px 40px;
  `}
`

const FeaturedPostColumn = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`

const FinnLogo = styled.img`
  width: 100%;
  max-width: 250px;
  padding: 0 20px 0 10px;
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: block;
    padding-top: 50px;
  `}
`

const RightGrid = styled.div`
  display: grid;
  justify-contents: center;
  ${media.mobile`
    grid-template-columns: 1fr;
    grid-gap: 40px 30px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}
  ${media.desktop`
    grid-gap: 60px 30px;
  `}
`

const RightGridTopRow = styled(RightGrid)`
  padding-bottom: 50px;
  justify-items: center;
  ${media.tablet`
    grid-template-columns: 1fr 2.5fr;
  `}
`

const VetGroup = styled.div`
  ${media.mobile`
    margin: 10px auto 10px auto;
    padding: 0 20px 0 10px;
  `}
  ${media.tablet`
    margin: 0;
    padding: 0 6px 0 0;
  `}
`

const VetQuote = styled(LargeP)`
  margin-bottom: 20px;
  ${media.mobile`
    font-size: 22px;
  `}
  ${media.desktop`
    font-size: 24px;
  `}
  &:before {
    content: '“';
    position: absolute;
    ${media.mobile`
      margin-left: -9px;
    `}
    ${media.tablet`
      margin-left: -12px;
    `}
  }
`

const TopSection = ({ featuredPosts }) => (
  <StaticQuery
    query={TopSectionQuery}
    render={(data) => {
      const { quote, url } = data.allMarkdownRemark.edges[0].node.frontmatter
      return (
        <Container>
          <FeaturedPostColumn>
            <FeaturedPost />
          </FeaturedPostColumn>
          <div>
            <RightGridTopRow>
              <FinnLogo src={SittingDog} alt="Finn sitting dog logo" />
              <VetGroup>
                <MonoHeader copy="Words from the vet" />
                <VetQuote>{quote} ”</VetQuote>
                <Link to={url} aria-label={quote}>
                  <ArrowButton label="Read article" />
                </Link>
              </VetGroup>
            </RightGridTopRow>
            <RightGrid>
              {featuredPosts.map((post) => (
                <SmallBlogCard
                  post={post.frontmatter}
                  key={post.frontmatter.slug}
                />
              ))}
            </RightGrid>
          </div>
        </Container>
      )
    }}
  />
)

const TopSectionQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { firstquote: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            quote
            url
          }
        }
      }
    }
  }
`

export default TopSection
